const regions = [
    {
        id: 0,
        name: "all",
    },
    {
        id: 2,
        name: "africa",
    }, 
    {
        id: 3,
        name: "americas" 
    },
    {
        id: 4,
        name: "asia",
    },
    { 
        id: 5, 
        name: "europe" 
    },
    { 
        id: 6,
        name: "oceania" 
    },
  ];

export default regions